<template>
  <div class="header">
    <div class="inline-flex items-center text-lg font-bold text-invest-primary">
      <ion-icon
        name="menu-outline"
        class="block mr-2 text-2xl sm:hidden"
        @click.prevent="openSidebar"
      />
      Hello {{ firstName }}
    </div>
    <div class="inline-flex items-center sm:px-12">
      <div class="relative">
        <ion-icon name="notifications" class="mr-6 text-2xl text-invest-dark" />
        <div
          class="absolute top-0 w-2 h-2 rounded-full right-7 bg-invest-purple"
          v-if="hasUnreadNotification"
        ></div>
      </div>
      <button
        class="h-10 px-3 text-xs btn text-invest-primary"
        style="background: rgba(39, 43, 63, 0.08)"
        type="button"
        @click="logout"
      >
        Log out
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import sessionModule from '@/modules/auth/sessionModule';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: () => {
    const { firstName } = sessionModule();

    const { commit, dispatch, state } = useStore();
    const { push } = useRouter();

    const openSidebar = () => commit('utils/toggleSidebar');

    const hasUnreadNotification = computed(
      () => state.utils.notifications.length,
    );

    const logout = () => dispatch('logout').then(() => push('/'));

    return {
      firstName,
      openSidebar,
      hasUnreadNotification,
      logout,
    };
  },
});
</script>
