
import { defineComponent, computed } from 'vue';
import sessionModule from '@/modules/auth/sessionModule';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: () => {
    const { firstName } = sessionModule();

    const { commit, dispatch, state } = useStore();
    const { push } = useRouter();

    const openSidebar = () => commit('utils/toggleSidebar');

    const hasUnreadNotification = computed(
      () => state.utils.notifications.length,
    );

    const logout = () => dispatch('logout').then(() => push('/'));

    return {
      firstName,
      openSidebar,
      hasUnreadNotification,
      logout,
    };
  },
});
